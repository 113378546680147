<template>
	<div>
		<vue-headful :title="$t('pageTitle')" />
		<confirm ref="confirm"></confirm>
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
			<v-icon class="secondary--text text--lighten-2"
				>fas fa-caret-right</v-icon
			>
      </template>
		</v-breadcrumbs>
		<v-toolbar>
			<v-toolbar-title>Face Directory</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-toolbar-items>
				<v-text-field
					v-model="searchInput"
					append-outer-icon="fas fa-search"
					@click:append-outer="SearchPersonList()"
					clearable
					@keyup.enter="SearchPersonList()"
					@click:clear="searchInput = null; LoadPersonList(true)"
					class="mr-3"
					label="Cerca Persone"
				></v-text-field>
				<v-btn color="info" @click="TrainModel" :loading="TrainingData"
					>Train Model</v-btn
				>
			</v-toolbar-items>
		</v-toolbar>
		<v-progress-linear
			:indeterminate="true"
			v-if="LoadingData"
		></v-progress-linear>

		<v-container v-else>

			<v-checkbox label="Visi da approvare" v-model="filterWaitingReview" v-if="isUserInRole('BusinessAdmin')" @change="LoadPersonList(true)"></v-checkbox>

			<v-row dense v-for="item in items" :key="item.FacePersonId">
				<v-col md="2" cols="4"
					><h6 class="text-h6">{{ item.Name }}</h6>
					<template v-if="isUserInRole('BusinessAdmin')">
						<v-btn icon @click="approve(item)" v-if="item.WaitingReview"
								><v-icon>fas fa-check</v-icon></v-btn
							>
						<v-btn icon @click="showUpdateDialog(item)"
							><v-icon>fas fa-edit</v-icon></v-btn
						>
						<v-btn icon @click="deletePerson(item.FacePersonId)"
							><v-icon>fas fa-trash</v-icon></v-btn
						>
						<div v-if="item.CreatedByProfile" class="grey--text" style="font-size:13px">
							aggiunto da {{ item.CreatedByProfile.DisplayName }} il {{item.CreateDate | formatDate }}
						</div>
						</template
					></v-col
				>
				<v-col md="10" cols="8">
					<v-container>
						<v-row dense>
							<v-col
								cols="2"
								v-for="photo in item.SGPFacePersonImages"
								:key="photo.FacePersonImageId"
								><v-img
									aspect-ratio="1"
									:src="photo.Url + SasToken"
									class="rounded facePersonImage"
								></v-img
							>
						<v-btn color="error" icon @click="removeFaceFromModel(item.FacePersonId, photo.FacePersonImageId)"><v-icon>far fa-file-minus</v-icon></v-btn>
						</v-col>
						</v-row>
					</v-container>
				</v-col>
			</v-row>
		</v-container>

		<div class="text-center" v-if="itemsPaging">
			<v-pagination
				:disabled="LoadingData"
				v-model="currentPage"
				:length="itemsPaging.TotalPages"
				:total-visible="5"
			></v-pagination>
		</div>
		<v-dialog max-width="400" v-model="showUpdatePersonDialog">
			<v-card>
				<v-card-title>Aggiorna Persona</v-card-title>
				<v-card-text>
					<v-text-field
						v-model="updateName"
						:label="$t('common.name')"
					></v-text-field>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="showUpdatePersonDialog = false">{{
						$t("common.cancel")
					}}</v-btn>
					<v-btn color="primary" @click="updatePerson">{{
						$t("common.update")
					}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import Confirm from "@/components/Shared/Common/Confirm";
import { mapActions, mapGetters } from "vuex";
export default {
	components: { Confirm },
	data() {
		return {
			LoadingData: false,
			items: [],
			itemsPaging: null, // TotalItems, Limit, Offset, CurrentPageIndex, TotalPages
			currentPage: 1,
			pageSize: 24,
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
			],

			facePersonName: null,
			TrainingData: false,
			showUpdatePersonDialog: false,
			searchInput: null,
			selectedPersonId: null,
			updateName: false,
			filterWaitingReview: false,
			timeoutHandler: null,

			controller: null,
		};
	},
	computed: {
		...mapGetters(["SasToken", "isUserInRole"]),
	},
	watch: {
		async currentPage() {
			await this.LoadPersonList();
		},

		// async searchInput(val) {
		// 	if (!val || val.length > 2) {
		// 		this.currentPage = 1;
		//
		// 		// wait for 300ms before calling LoadPersonList
		// 		// if searchInput is called another time, cancel the initial call
		// 		clearTimeout(this.timeoutHandler);
		//
		// 		this.timeoutHandler = setTimeout(async () => {
		// 			this.controller.abort();
		// 			await this.LoadPersonList(true);
		// 		}, 300);
		// 	}
		// },
	},

	created() {
		this.FacePersonService = new CrudClient("Face/Person");
	},
	async mounted() {
		this.breadcrumbsItems.push({
			text: this.$t("pageTitle"),
			disabled: true,
			exact: true,
			to: "/FacePerson",
		});

		await this.LoadPersonList();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async approve(item) {
			try {
				this.LoadingData = true;

				await this.FacePersonService.Post(item.FacePersonId, null, "Approve");
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.LoadingData = false;
			}

			await this.LoadPersonList();
		},

		async SearchPersonList() {
			try {
				this.LoadingData = true;
				this.currentPage = 1;

				let filter = "";

				if (this.searchInput) {
					filter = "Name:like:" + encodeURIComponent(this.searchInput);
				}

				if (this.filterWaitingReview) {
					if (filter) {
						filter += ";"
					}
					filter += "WaitingReview:true"
				}

				if (this.controller) {
					this.controller.abort();
				}

				this.controller = new AbortController();
				const res = await this.FacePersonService.GetPaged({
					limit: this.pageSize,
					skip: (this.currentPage - 1) * this.pageSize,
					fields: "*, SGPFacePersonImages.*",
					orderBy: "Name",
					filter: filter,
					signal: this.controller.signal
				});

				if (!res) {
					// aborted
					return;
				}

				this.itemsPaging = res.Paging;
				this.items = res.Data;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.LoadingData = false;
			}
		},

		async LoadPersonList(resetPaging) {
			try {
				this.LoadingData = true;
				if (resetPaging) {
					this.currentPage = 1;
				}

				let filter = "";

				if (this.filterWaitingReview) {
					if (filter) {
						filter += ";"
					}
					filter += "WaitingReview:true"
				}

				if (this.controller) {
					this.controller.abort();
				}

				this.controller = new AbortController();
				const res = await this.FacePersonService.GetPaged({
					limit: this.pageSize,
					skip: (this.currentPage - 1) * this.pageSize,
					fields: "*, SGPFacePersonImages.*",
					orderBy: "Name",
					filter: filter,
					signal: this.controller.signal
				});

				if (!res) {
					// aborted
					return;
				}

				this.itemsPaging = res.Paging;
				this.items = res.Data;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.LoadingData = false;
			}
		},

		showUpdateDialog(facePerson) {
			this.selectedPersonId = facePerson.FacePersonId;
			this.updateName = facePerson.Name;
			this.showUpdatePersonDialog = true;
		},

		async updatePerson() {
			try {
				const dto = {
					Name: this.updateName,
				};
				await this.FacePersonService.Patch(this.selectedPersonId, dto, false);
				this.snackSuccess({ Text: this.$t("common.item.updated") });

				this.updateName = null;
				this.selectedPersonId = null;
				this.showUpdatePersonDialog = false;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotUpdateItem") });
			}

			await this.LoadPersonList();
		},

		async deletePerson(facePersonId) {
			if (
				!(await this.$refs.confirm.open(
					this.$t("common.delete"),
					this.$t("common.confirmDelete")
				))
			) {
				return;
			}

			try {
				await this.FacePersonService.Delete(facePersonId);
				this.snackSuccess({ Text: this.$t("common.item.deleted") });
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotDeleteItem") });
			}

			await this.TrainModel()
			await this.LoadPersonList();
		},

		async removeFaceFromModel(facePersonId, facePersonImageId) {
			if (
				!(await this.$refs.confirm.open(
					this.$t("common.delete"),
					this.$t("common.confirmDelete")
				))
			) {
				return;
			}

			try {
				await this.FacePersonService.Delete(`${facePersonId}/${facePersonImageId}`);
				this.snackSuccess({ Text: this.$t("common.item.deleted") });
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotDeleteItem") });
			}

			await this.TrainModel()
			await this.LoadPersonList();
		},

		async TrainModel() {
			try {
				this.TrainingData = true;
				await this.FacePersonService.Post(null, null, "Train");

				this.snackSuccess({ Text: this.$t("Training Completed") });
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.TrainingData = false;
			}
		},
	},
};
</script>
<style lang="scss">
.facePersonImage {
	width: 100%;

	.facePersonImageInner {
		display: none;
	}
}
</style>>
<i18n>
{
	"it": {
		"pageTitle": "Elenco persone per riconoscimento"
	}
}
</i18n>
